.slider {
  position: relative;
}

.slider-wrap {
  padding-top: 120px;
}

.carousel-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 64px;
  border-radius: 50%;
  border: 1px solid var(--text-color);
  background-color: transparent;
  cursor: pointer;

}

.carousel-arrow-wrap {
  position: absolute;
  left: 0;
  z-index: 1;
bottom: 0;
}

.carousel-arrow svg {
  transition: all .3s ease;
}

.carousel-arrow:hover svg {
  width: 40px;
  height: 40px;
}

.carousel-arrow-wrap.prev button {
  transform: rotate(180deg);
}

.carousel-arrow-wrap.next {
  left: 88px;
}

.slick-slider {
  position: static !important;
  border: 1px solid var(--border-color)
}

.slide-titles {
  position: relative;
  width: 100%;
}

.slide-title {
  text-transform: uppercase;
  width: 100%;
  position: absolute;
  opacity: 0;
}

.slider-item_img {
  height: auto;
  width: 100%;
  min-width: 100%;
}

@media only screen and (max-width: 768px) {

  .slider-wrap {
    padding-top: 32px;
  }

  .slick-slider {
    position: relative !important;
    min-height: 200px;
    align-items: center;
    margin-top: 24px;
  }

  .carousel-arrow-wrap {
    top: 50%;
    transform: translateY(-50%);
  }

  .slide-title.active {
    position: relative;
  }

  .carousel-arrow {
    height: 48px;
    width: 48px;
  }

  .carousel-arrow-wrap {
    bottom: auto;
    left: -12px;
  }

  .carousel-arrow svg {
    width: 30px;
    height: 30px;
  }

  .carousel-arrow-wrap.next {
    right: -12px;
    left: auto;
  }

  .carousel-arrow:hover svg {
    width: 34px;
    height: 34px;
  }
}