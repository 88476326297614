:root {
  --main-bg-color: #F6F9FA;
  --success-color: #E2F6EC;
  --text-color: #111930;
  --muted-color: #A1B7C8;
  --error-color: #FA6D6D;
  --brown-color: #DF9B69;
  --border-color: #E6EEF1;
  --tint-color: #FFF885;
  --yellow-color: #E8CF50;
  --blue-color: #8092EE;
  --green-color: #65D199;

  --font-refular: 'gilroy-regular', sans-serif;
  --font-medium: 'gilroy-medium', sans-serif;
  --font-bold: 'gilroy-bold', sans-serif;

  --text-size: 16px;
  --text-line-height: 19px;
}
