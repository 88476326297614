

.made-simple_list {
  padding-top: 120px;
}

.made-simple_item-img {
  width: 120px;
  height: 120px;
}

@media only screen and (max-width: 768px) {
  .made-simple_descr {
    padding-left: 12px !important;
    padding-top: 16px;
  }

  .made-simple_list {
    padding-top: 32px;
  }

  .made-simple_item {
    padding: 0;
  }

  .made-simple_item-img {
    width: 80px;
    height: 80px;
  }
}

@media only screen and (max-width: 576px) {
  .made-simple_list {
    padding-top: 0;
  }

  .made-simple_item {
    padding-top: 32px;
  }
}
