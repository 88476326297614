.creators_list {
  margin-bottom: -32px;
  padding-top: 88px;
}
.creator_item {
  padding: 32px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
}
.creator_item:last-child {
  border: none;
}
@media only screen and (max-width: 768px) {
  .creators_list {
    padding-top: 0;
    margin-bottom: -16px;
  }
  .creator_item {
    padding: 16px 0;
  }
}