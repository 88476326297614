.welcome {
  height: 488px;
  margin: 64px -50px 48px;
  background: 90% 60px/ 60% no-repeat url("../../assets/images/main.png");
  background-color: var(--main-bg-color);
  overflow: hidden;
  padding: 32px 50px;
}

.welcome-title_icon {
  min-width: 56px;
  width: 56px;
  height: 56px;
  margin-top: -5px;
}
.welcome-descr {
  width: min(600px, 100%);
  margin-top: 16px;
  margin-left: 10px;
}
.welcome-btn {
  margin-top: 48px;
  height: 64px !important;
  width: auto;
  float: left;
  font-size: 20px!important;
  margin-left: 10px;
}

@media only screen and (max-width: 1200px) {
  .welcome {
    margin-left: 0;
    margin-right: 0;
    height: auto;
    margin-top: 32px;
    margin-bottom: 0;
  }
  .welcome-title_icon {
    min-width: 40px;
    width: 40px;
    height: 40px;
  }
}
@media only screen and (max-width: 768px) {
  .welcome-descr {
    margin-left: 0;
  }
  .welcome-btn {
    margin-left: 0;
    margin-top: 24px;
  }
}
@media only screen and (max-width: 576px) {
  .welcome {
    padding: 24px;
    background: var(--main-bg-color);
  }

}