.cookie-modal p {
  margin: 10px 0;
}

.cookie-modal table {
  margin: 16px 0;
  border: 1px solid var(--border-color);
  border-collapse: collapse;
}
.cookie-modal table td, .cookie-modal table th{
  border: 1px solid var(--border-color);
}