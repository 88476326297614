@font-face { /*700*/
  font-family: 'gilroy-bold';
  src: url('../fonts/gilroy-bold.eot');
  src: url('../fonts/gilroy-bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/gilroy-bold.woff') format('woff'),
  url('../fonts/gilroy-bold.ttf') format('truetype'),
  url('../fonts/gilroy-bold.svg#webfont') format('svg');
}

@font-face {/*500*/
  font-family: 'gilroy-medium';
  src: url('../fonts/gilroy-medium.eot');
  src: url('../fonts/gilroy-medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/gilroy-medium.woff') format('woff'),
  url('../fonts/gilroy-medium.ttf') format('truetype'),
  url('../fonts/gilroy-medium.svg#webfont') format('svg');
}
@font-face {/*400*/
  font-family: 'gilroy-regular';
  src: url('../fonts/gilroy-regular.eot');
  src: url('../fonts/gilroy-regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/gilroy-regular.woff') format('woff'),
  url('../fonts/gilroy-regular.ttf') format('truetype'),
  url('../fonts/gilroy-regular.svg#webfont') format('svg');
}

/* -------------------------- */
