.roles {
  margin-bottom: -32px;
}

.roles_list {
  padding-top: 120px;
}
.role_item {
  margin-bottom: 32px;
}
.role_item-icon {
  height: 56px;
  width: 56px;
  border-radius: 112px;
  background-color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .roles_list {
    padding-top: 32px;
  }
  .role_item {
    padding: 0;
  }
}