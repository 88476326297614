.plan_item {
  background-color: #F6F9FA;
  padding: 32px;
  border-radius: 32px;
  position: relative;
  transition: all .3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.plan_item:hover {
  background: linear-gradient(45deg, #B6E8CE 0%, #FFF885 100%);
}
.plan_item_recommended {
  background-color: var(--text-color);
  color: #fff;
  position: absolute;
  height: 32px;
  padding: 0 12px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  top: -16px;
}
.plan h3 {
  margin-bottom: 120px;
}

@media only screen and (max-width: 991px) {
  .plan_item {
    padding: 24px;
    .btn {
      height: 40px;
    }
  }
  .plan_item_recommended {
    height: 20px;
    font-size: 14px;
    top: -10px;
  }
  .plan h3 {
    margin-bottom: 120px;
  }
}

@media only screen and (max-width: 768px) {
  .plan h3 {
    margin-bottom: 32px;
  }
}