footer {
  margin: 0 -50px;
  padding: 32px 50px;
  background-color: var(--main-bg-color);
  height: 280px !important;
  position: relative;
}

footer .title {
  position: relative;
  z-index: 1;
}

.footer-title_icon {
  min-width: 56px;
  width: 56px;
  height: 56px;
  margin-top: -5px;
}

.footer-btn {
  margin-top: 48px;
  height: 64px !important;
  width: auto;
  float: left;
  font-size: 20px !important;
  z-index: 1;
  position: relative;
}

.footer-cactus {
  position: absolute;
  right: 10%;
  bottom: 0;
  width: 280px;
  height: auto;
}

@media only screen and (max-width: 1200px) {
  footer {
    margin-left: 0;
    margin-right: 0;
    height: auto;
    margin-top: 32px;
    margin-bottom: 0;
  }

}

@media only screen and (max-width: 992px) {
  .footer-cactus {
    right: 40px;
    width: 180px;
  }
}

@media only screen and (max-width: 768px) {
  .footer-title_icon {
    min-width: 40px;
    width: 40px;
    height: 40px;
  }

  footer {
    height: auto !important;
    padding: 24px;
  }
  .footer-btn {
    float: initial;
  }
}

@media only screen and (max-width: 576px) {
  .footer-cactus {
    display: none;
  }

}