header {
  display: flex;
  align-items: center;
  height: 72px;
  position: fixed;
  top: 0;
  background-color: #fff;
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid var(--text-color);
}

.logo {
  width: 132px;
  height: auto;
}