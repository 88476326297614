::-webkit-scrollbar, iframe body::-webkit-scrollbar, .ant-select-dropdown::-webkit-scrollbar, .body-inside-iframe::-webkit-scrollbar {
  width: 2px !important;
  height: 2px !important;
  background-color: transparent !important;
  /*border: 1px solid #d5d5d5 !important;*/
}

::-webkit-scrollbar-thumb, iframe body::-webkit-scrollbar-thumb, .ant-select-dropdown::-webkit-scrollbar-thumb, .body-inside-iframe::-webkit-scrollbar-thumb {
  background-color: var(--border-color) !important;
  border: 2px solid var(--border-color) !important;
}

html {
  scroll-behavior: smooth;
  height: 100vh;
}

body {
  font-family: var(--font-refular);
  font-size: 16px;
  line-height: 24px;
  padding-top: 0;
  margin: 0;
  background-color: #fff;
  overflow-x: auto;
  height: 100vh;
  width: 100%;
}

main {
  padding-bottom: 32px;
  margin-top: 136px;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
  color: var(--text-color);
  font-size: 16px;
  font-family: var(--font-refular);
}

ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

p {
  margin-bottom: 0;
}

a {
  color: var(--muted-color);
}

a:hover, a:active {
  color: var(--text-color);
}

.muted {
  color: var(--muted-color);
}

.muted-14 {
  font-size: 14px;
  line-height: 21px;
  color: var(--muted-color);
}

.muted-12 {
  font-size: 12px;
  line-height: 14px;
  color: var(--muted-color);
}

.bold {
  font-family: var(--font-bold) !important;
}

.bold-14 {
  font-size: 14px;
  line-height: 21px;
  font-family: var(--font-bold);
}

.bold-20 {
  font-size: 20px;
  line-height: 30px;
  font-family: var(--font-bold);
}

.medium {
  font-size: 16px;
  line-height: 24px;
  font-family: var(--font-medium);
}

.medium-12 {
  font-size: 12px;
  line-height: 12px;
  font-family: var(--font-medium);
}

.medium-14 {
  font-size: 14px;
  line-height: 16px;
  font-family: var(--font-medium);
}

.medium-20 {
  font-size: 20px;
  line-height: 24px;
  font-family: var(--font-medium);
}

.separator {
  background-color: var(--text-color);
  width: 100%;
  height: 1px;
  margin: 32px 0;
}

.medium-24 {
  font-size: 24px;
  line-height: 29px;
  font-family: var(--font-medium);
}

.text-20 {
  font-size: 20px;
  line-height: 30px;
}

.title-32 {
  font-size: 32px;
  line-height: 48px;
  font-family: var(--font-bold);
}

.title {
  font-size: 48px;
  line-height: 56px;
  font-family: var(--font-bold);
  text-transform: uppercase;
}

.text-14 {
  font-size: 14px;
  line-height: 21px;
}

.text-12 {
  font-size: 12px;
  line-height: 14px;
}

.btn {
  outline: none;
  padding: 0 32px;
  min-height: 40px;
  height: 40px;
  border-radius: 50px;
  font-family: var(--font-medium);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--text-color);
  transition: all .3s ease;
}

.btn-primary {
  font-size: 16px;
  background-color: var(--text-color);
}
.btn-primary.disabled, .btn-primary:disabled {
  background-color: var(--text-color);
  border-color: var(--text-color);
}
.btn-outline {
  background-color: #fff;
  border-color: var(--text-color) !important;
}

.btn-outline:hover {
  border-color: var(--text-color) !important;
}

.btn:focus, .btn:active {
  outline: none;
  box-shadow: none;
}

.btn-check:active + .btn-primary:focus, .btn-check:checked + .btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-lg {
  font-family: var(--font-medium);
  font-size: 16px;
  height: 64px;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  border: 1px solid var(--text-color);
  background-color: var(--text-color);
  box-shadow: none;
  transform: translateY(1px);
}

.btn-outline {
  border: 1px solid var(--text-color);
  color: var(--text-color);
}


.input {
  font-size: 16px;
  width: 100%;
  height: 48px;
  border: 1px solid var(--border-color);
  background-color: var(--main-bg-color) !important;
  border-radius: 28px;
  outline: none;
  padding: 0 24px;
  font-family: var(--font-refular);
}

.input::placeholder {
  color: var(--muted-color);
  font-family: var(--font-refular);
}

textarea.input {
  border-radius: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.input-small {
  padding-left: 16px;
  height: 32px;
  font-size: 14px;
  font-family: var(--font-medium);
}

.input-small::placeholder {
  font-size: 14px;
  font-family: var(--font-refular);
}


.pointer {
  cursor: pointer;
}

.animate__animated {
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}


@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -50px, 0);
    transform: translate3d(0, -50px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50px, 0);
    transform: translate3d(0, 50px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.terms-content a, .terms-link {
  cursor: pointer;
}

.terms-content a:hover, .terms-link:hover {
  text-decoration: underline;
}

.terms-content h3 {
  font-family: var(--font-bold);
  font-size: 24px;
}

.terms-content h4 {
  font-family: var(--font-bold) !important;
  font-size: 20px;
}

.terms-content h3, .terms-content h4, .terms-content p {
  margin: 16px 0;
}

.terms-content ul {
  list-style: disc;
  padding-left: 32px;
}
.terms-content li {
  margin: 10px 0;
}
ol {
  counter-reset: item;
  padding-left: 0;
}
ol > li {
  position: relative;
  display: block;
}
ol > li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
}
/*ol li:before {*/
/*  content: counters(item, ".");*/
/*  counter-increment: item;*/
/*  position: absolute;*/
/*  margin-right: 100%;*/
/*  right: 10px; !* space between number and text *!*/
/*}*/

input {
  font-size: 16px;
  width: 100%;
  height: 48px;
  border: 1px solid #E6EEF1;
  border-radius: 28px;
  outline: none;
  padding: 0 24px;
}
input[type='checkbox'] {

  padding: 0;
}
@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 992px) {

}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 32px;
    line-height: 1.3;
  }

  .text-20 {
    font-size: 16px;
    line-height: 1.3;
  }

  .separator {
    margin: 24px 0;
  }

  main {
    padding-bottom: 32px;
    margin-top: 96px;
  }
}

@media only screen and (max-width: 576px) {

}
